import { template as template_d1db4aaf8ca14cb6877b72920363f37b } from "@ember/template-compiler";
const SidebarSectionMessage = template_d1db4aaf8ca14cb6877b72920363f37b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
