import { template as template_bb4bf48723c54621a24b04cc69b49418 } from "@ember/template-compiler";
const WelcomeHeader = template_bb4bf48723c54621a24b04cc69b49418(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
